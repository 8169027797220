<template>
  <div>
    <header>
    <SecondNavBar/>
    </header>
      <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <h5 class="col-sm-12" id="terms-title">
            <strong>Terms of Service and License Agreement</strong>
          </h5>
          <div style="margin-left:20px;">
               <p>Last updated on January 30th 2023</p>
               <p>Home for Life Design, Inc., a North Carolina Limited Liability Corporation, “Home for Life Design”), owns and/or operates the web pages available at www.Home for Life Design.com, and all sub-domains thereof (collectively, the “Site”). Access to and use of the software (“Software”) including the Home For Life Design Home Assessment mobile app, and services (“Services”) available on or through the Site are governed by this Terms of Service and License Agreement (this “Agreement”).</p>
               <p>THIS IS A LEGALLY BINDING AGREEMENT. IF YOU DO NOT UNDERSTAND THIS AGREEMENT, OR DO NOT AGREE TO BE BOUND BY IT OR THE PRIVACY POLICY REFERENCED HEREIN, YOU MUST IMMEDIATELY LEAVE THE SITE AND YOU ARE NOT AUTHORIZED TO ACCESS OR USE ANY OF THE SERVICES OR SOFTWARE. BY ACCESSING OR USING THE SITE, SERVICES AND/OR SOFTWARE, YOU CONFIRM THAT YOU ARE AT LEAST 18 YEARS OLD, THAT YOU ARE LEGALLY ABLE TO ENTER INTO THIS AGREEMENT, AND THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT.</p>
               <p>If you are an individual paying subscriber, this Agreement is between you, individually, and Home for Life Design.</p>
               <p>If you are an employee of a hospital, company, or other entity or organization that has subscribed to the Services and Software (an “Enterprise Subscriber”) and has authorized you to use the Site, Services and Software (an “Authorized User”), this Agreement is an agreement between you and the Enterprise Subscriber, on the one hand, and Home for Life Design, on the other hand. Home for Life Design may seek recourse against you for any violation of the terms of this Agreement.</p>
               <p>If you are entering into this Agreement on behalf of an Enterprise Subscriber, you represent that you have the authority to bind the Enterprise Subscriber to this Agreement, in which case references to “you” in this Agreement shall mean the Enterprise Subscriber. If you do not have such authority, you must not accept this Agreement and may not use the Site, Software or Services.</p>
               <p><strong>License</strong></p>
               <p>In consideration for your agreement to this Agreement and your payment of all applicable Fees (as defined below), Home for Life Design grants you a personal, limited, non-exclusive, non-sub-licensable, non-transferable, revocable license to access and make use of the Site, the Services and the Software, subject to the terms and conditions set forth in this Agreement. If you are an Enterprise Subscriber, you may permit such number of Authorized Users as has been agreed between you and Home for Life Design to access and make use of the Site, Services and Software.</p>
               <p>You may access and use the Site, Services and Software solely for your own personal purposes and only in accordance with any instruction manuals, user guides and other documentation as made available by Home for Life Design from time to time (“Documentation”).</p>
               <p>If you are an Individual Subscriber or Enterprise Subscriber, the following restrictions apply.</p>
               <p>You may not:</p>
               <ul style="list-style-type:none !important;">
                  <li>(a) copy, modify or create derivative works based on the Site, Services, Software or Documentation, or any portion(s) of any of the foregoing (individually and collectively, “Home for Life Design IP”);</li> 
                  <li>(b) distribute, transmit, publish or otherwise disseminate any Home for Life Design IP;</li>
                  <li>(c) download or store any Home for Life Design IP except to the extent explicitly permitted on the Site;</li>
                  <li>(d) transfer to any third party any of your rights under this Agreement;</li>
                  <li>(e) access or use the Services, Software or Home for Life Design IP for the benefit of any third party;</li>
                  <li>(f) access content or data not intended for you, log onto a server or account that you are not authorized to access, or otherwise violate or attempt to violate any security or authentication feature or measures of the Site, Software or Services;</li>
                  <li>(g) attempt to access or derive the source code or architecture of any Software;</li>
                  <li>(h) attempt to probe, scan or test the vulnerability of the Site, Services and/or Software, or any associated system or network, or to breach any security or authentication feature or measures of the Site, Software or Services, and if you are blocked by Home for Life Design from accessing the Site, Software or Services (including by blocking your IP address), you will not implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address);</li>
                  <li>(i) interfere or attempt to interfere with service to any user, host or network, including, without limitation, by means of submitting malicious software or computer code (“Malicious Code”) to the Site or Services, load testing, overloading, “flooding,” “spamming,” “mail bombing,” “crashing,”;</li>
                  <li>(j) email or otherwise transmit any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary relationships; (iii) contains any Malicious Code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person or entity; (v) constitutes unsolicited or unauthorized materials; or (vi) is otherwise objectionable;</li>
                  <li>(k) automate access to the Site or the Services, including, without limitation, through the use of APIs, bots, scrapers or other similar devices;</li>
                  <li>(l) export or re-export any Home for Life Design IP;</li>
                  <li>(m) use or access any Services, Software or Home for Life Design IP in order to build a competitive product, service or solution;</li>
                  <li>(n) violate any applicable law or regulations in connection with your use of the Site, Services or Software;</li>
                  <li>(o) impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity, including, without limitation, an Enterprise Subscriber; or</li>
                  <li>(p) permit any third party to do any of the foregoing.</li>
               </ul>
               <p>Home for Life Design may offer certain Software, including certain interfaces, for download from the Site (“Ancillary Software”). Subject to the other terms and conditions of this Agreement, you may install and use Ancillary Software on computers owned, leased or otherwise controlled by you, solely for your personal use in conjunction with your authorized use of the Services. Upon expiration or termination of this Agreement for any reason, you shall cease any further use of the Ancillary Software and shall promptly destroy all copies thereof in your possession.</p>
               <p>Certain Services or Software (including Ancillary Software) may be subject to additional limitations, restrictions, terms and/or conditions specific to such Services or Software (“Specific Terms”). In such cases, the applicable Specific Terms will be made available to you and your access to and use of the relevant Services or Software will be contingent upon your acceptance of and compliance with such Specific Terms.</p>
               <p><strong>Registration and Security</strong></p>
                <p>By completing the registration process for any given Services, you are agreeing to subscribe to the selected Services, subject to the terms and conditions of this Agreement. You agree to provide Home for Life Design with accurate and complete registration information, and to promptly notify Home for Life Design in the event of any changes to any such information.</p>
                <p>You shall be solely responsible for the security and proper use of all user IDs, passwords or other security devices used in connection with the Site, Software, and/or the Services, and shall take all reasonable steps to ensure that they are kept confidential and secure, are used properly and are not disclosed to or used by any other person or entity. You shall immediately inform Home for Life Design if there is any reason to believe that a user ID, password or any other security device issued by Home for Life Design has or is likely to become known to someone not authorized to use it, or is being or is likely to be used in an unauthorized way. Home for Life Design reserves the right (at its sole discretion) to request that you change your password(s) in connection with the Services, and you shall promptly comply with any such request.</p>
                <p>You are solely responsible for all activity in connection with access to the Site, Software, and/or Services through your account or using your password, and for the security of your computer systems, and in no event shall Home for Life Design be liable for any loss or damages relating to such activity.</p>
                <p><strong>Enterprise Subscriber Responsibility for Authorized Users</strong></p>
                <p>Enterprise Subscribers shall ensure that the total number of Authorized Users does not exceed the maximum number of seats authorized by and purchased from Home for Life Design (each of which seats represents a unique (named) Authorized User). Authorized Users may not transfer (including by way of sublicense, lease, assignment or other transfer, including by operation of law) their seat, user name or right to use the Site, Services or Software to any third party. You, the Enterprise Subscriber, are solely responsible for the way your Authorized Users use the Site, Services and Software, and for ensuring that all of your Authorized Users comply with all of the terms and conditions of this Agreement. Any violation of the terms and/or conditions of this Agreement by an Authorized User shall be deemed to be a violation thereof by you.</p>
                <p><strong>Fees and Payments</strong></p>
                <p>Unless you are an Enterprise Subscriber or Authorized User thereof, your access to or use of the Services and/or Software shall be contingent upon your payment of all applicable fees as described on the Site from time to time (“Fees”). Enterprise Subscribers shall pay the fees set forth in the invoice(s) rendered by Home for Life Design in accordance with the payment terms set forth therein.</p>
                <p>Upon registering for Services, unless you are an Enterprise Subscriber or Authorized User thereof, you will be required to designate a valid credit card account. You hereby authorize Home for Life Design to charge to your designated account all Fees relating to the Services you select, and you agree to pay all such Fees in accordance with the applicable card member agreement terms and conditions.</p>
                <p>Home for Life Design reserves the right to revise its Fees, including by increasing or adding new Fees, at any time on ten (10) days’ notice. Such notice may be sent to you by email to your most recently provided email address or posted on the Site or by any other manner chosen by Home for Life Design in its commercially reasonable discretion. You will be deemed to have received any such notice that is posted on the Site on the day it was posted. Your use of the Services after the ten (10) day notice period constitutes your acceptance of the new or revised Fees. If you do not agree to the revised Fees, you may cancel your subscription by following the “View cancellation instructions” link below.</p>
                <p><strong>Continuous membership</strong></p>
                <p>To ensure uninterrupted service, all subscriptions to the Site, Software and Services are renewed automatically. However, you must pay the renewal fees through the Site within 30 days of notification by Home for Life Design. Failure to pay the renewal fees within 30 days will result in the immediate termination of the Software and Service. All subscriptions are renewed at the subscription level(s) in effect at the time the then-current subscription term ends.</p>
                <p><strong>Termination and Refund Policy</strong></p>
                <p>This Agreement shall continue until you cancel your subscription or until terminated by Home for Life Design. You may cancel your subscription at any time. View cancellation instructions at http://www.Home for Life Design.com. Except as may be expressly set forth herein, all Fees paid or accrued in connection with any Services are non-refundable, and Home for Life Design will not prorate any Fees paid for a subscription that is terminated before the end of its term.</p>
                <p>Home for Life Design may deny you access to all or any part of the Services or terminate your account with or without prior notice if you engage in any conduct or activities that Home for Life Design determines, in its sole discretion, violate this Agreement or the rights of Home for Life Design or any third party, or is otherwise inappropriate.</p>
                <p>Upon termination of your account(s), your right to use the Services and Software and to access the Site and any of its content will immediately cease. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, limitations of liability and miscellaneous provisions.</p>
                <p><strong>Access to Services</strong></p>
                <p>You are responsible for obtaining and maintaining any equipment and ancillary services needed to connect to or access the Site or otherwise use the Services, including, without limitation, modems, hardware, software, internet service and telecommunications capacity. You shall be solely responsible for ensuring that such equipment and ancillary services are compatible with the Services and Software.</p>
                <p><strong>Mobile Services</strong></p>
                <p>The Site, Software, and Services include products and services that are available via a mobile device, including (i) the ability to upload content to the Services via a mobile device, (ii) the ability to browse the Services and the Site from a mobile device, and (iii) the ability to access certain features through an application downloaded and installed on a mobile device (collectively, “Mobile Services”). To the extent you access the Service through a mobile device, your wireless service carrier’s standard charges, data rates and other fees may apply. In addition, downloading, installing or using certain Mobile Services may be prohibited or restricted by your carrier, and not all Mobile Services may work with all carriers or devices. By using the Mobile Services, you agree that Home for Life Design may communicate with you regarding Home for Life Design and other entities by electronic means to your mobile device and that certain information about your usage of the Mobile Services may be communicated to us.</p>
                <p><strong>User Content</strong></p>
                <p>You are solely responsible for all text, documents or other content or information uploaded, entered or otherwise transmitted by you in connection with your use of the Services and/or Software (“User Content”). User Content includes, among other things, any mistakes contained in the content or information transmitted by you. Home for Life Design has no obligation to monitor any User Content and shall have no liability to you or any other person or entity with respect thereto, including, without limitation, liability with respect to any information (including confidential information) contained in or apparent from any User Content. You warrant, represent and covenant that you own or have valid and enforceable permission to use all User Content, and that no User Content infringes, misappropriates or violates the rights of any person or entity or any applicable law, rule or regulation of any government authority of competent jurisdiction. Home for Life Design is not responsible for the loss, corruption or other changes to User Content. Without limiting the foregoing, any feature(s) of the Services and/or Software that may permit you to temporarily save or otherwise store certain User Content is offered for your convenience only and does not guarantee that the User Content will be retrievable. You are solely responsible for saving, storing and otherwise maintaining User Content including by maintaining backup copies of your User Content on appropriate independent systems that do not rely on the Services and/or Software.</p>
                <p>By uploading or entering any User Content, you give Home for Life Design (and those it works with) permission to copy, store and use your User Content in connection with the provision of the Software and the Services.</p>
                <p><strong>Changes to Services or Terms</strong></p>
                <p>Home for Life Design reserves the right at any time to (i) change any information, specifications, features or functions of the Site, Services or Software, (ii) suspend or discontinue, temporarily or permanently, any or all of the Services, including the availability of any feature, database or content, or (iii) impose limits on certain features and Services or restrict access to parts or all of the Services in each case with or without prior notice and without any liability to you or any third party. Home for Life Design will use its commercially reasonable efforts to notify you of changes to the Services and/or Software that, in Home for Life Design’s reasonable opinion, have the effect of materially and adversely diminishing the functionality of the Services to which you have subscribed.</p>
                <p>Home for Life Design may from time to time update or revise this Agreement. If Home for Life Design updates or revises this Agreement, Home for Life Design will notify you either by email to your most recently provided email address, by posting the updated or revised Terms of Service and End User License Agreement on the Site or by any other manner chosen by Home for Life Design in its commercially reasonable discretion. Your use of the Site, Services or Software following any such update or revision constitutes your agreement to be bound by and comply with this Agreement as updated or revised. You can view the most current Terms of Service and End User License Agreement at https://www.Home for Life Design.com. It is your responsibility to review the Terms of Service and End User License Agreement periodically.</p>
                <p><strong>Links to Third Party Sites</strong></p>
                <p>Any links on the Site to third party websites are provided for your convenience only. If you choose to access third party websites or obtain products or services from third parties, you do so entirely at your own risk and such access is between you and such third party. Home for Life Design does not warrant or make any representation regarding the legality, accuracy or authenticity of content presented by such websites or any products or services offered by third parties and shall have no liability for any loss or damages arising from the access or use of such websites, products or services.</p>
                <p><strong>Consent to Receive Email</strong></p>
                <p>Your registration to use the Site and/or Services constitutes your consent to receive email communications from Home for Life Design, including messages regarding customer service issues and other matters. You may opt not to receive email correspondence, other than technical notifications and email regarding issues related to your account and your use of the Site and Services, at any time by following the link included in the email messages.</p>
                <p><strong>Data Collection and Privacy</strong></p>
                <p>Home for Life Design does not collect personally identifiable information from you except to the extent you have explicitly given such information to Home for Life Design. Home for Life Design’s information practices are further described in its privacy policy, which is available at: https://www.Home for Life Design.com (the “Privacy Policy”). The Privacy Policy is an integral part of this Agreement and is expressly incorporated by reference, and by entering into this Agreement you agree to (i) all of the terms of the Privacy Policy, and (ii) Home for Life Design’s utilization of data as described in the Privacy Policy is not an actionable breach of your privacy or publicity rights.</p>
                <p>Home for Life Design may from time to time update or revise the Privacy Policy. If Home for Life Design updates or revises the Privacy Policy, Home for Life Design will notify you either by email to your most recently provided email address, by posting the updated or revised Privacy Policy on the Site or by any other manner chosen by Home for Life Design in its commercially reasonable discretion. Your use of the Site, Services or Software following any such update or revision constitutes your agreement to be bound by and comply with the Privacy Policy as updated or revised.</p>
                <p><strong>Ownership</strong></p>
                <p>All User Content are and shall remain your property, and Home for Life Design shall acquire no right of ownership or use with respect to any User Content except in connection with its provision of the Services under this Agreement.</p>
                <p>All intellectual property rights in and to the Software, Site and Services and other Home for Life Design IP are and shall remain the sole property of Home for Life Design and its affiliates and licensors, as applicable, and you shall acquire no right of ownership or use with respect to any Software or other Home for Life Design IP except as specified in this Agreement. You may from time to time provide Home for Life Design with suggestions, comments, recommendations and/or feedback regarding the Services, the Software and/or Home for Life Design’s related technologies (“Feedback”). Any and all Feedback is and shall be given entirely voluntarily. As between the you, Home for Life Design and, if applicable, the Enterprise Subscriber, all Feedback shall be exclusively owned by Home for Life Design, and you hereby make all assignments necessary to accomplish the foregoing ownership, and as a result Home for Life Design shall be freely entitled to reproduce, prepare derivative works, disclose to third parties, display and perform (publicly or otherwise), sell, lease, license, distribute and otherwise use and exploit any and all such Feedback as it deems appropriate, at its sole discretion, without obligation or liability of any kind to you, the Enterprise Subscriber or any other person or entity.</p>
                <p><strong>Indemnity</strong></p>
                <p>You shall indemnify, release and hold harmless Home for Life Design and its parents, subsidiaries and affiliates, and each of their respective officers, directors, employees and agents, from and against any loss, liability (including settlements, judgments, fines and penalties) and costs (including reasonable attorney fees, court costs and other litigation expenses) relating to any claim or demand made by any third party due to or arising out of your access to the Site, use of the Services or Software, violation of this Agreement, or infringement of any intellectual property or other right of any person or entity. If you are a California resident, you waive California Civil Code Section 1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.” If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.</p>
                <p><strong>Warranty Disclaimers</strong></p>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLIABLE LAW, HOME FOR LIFE DESIGN EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, REGARDING THE SITE, SERVICES AND SOFTWARE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT. YOU AGREE THAT YOUR USE OF THE SITE, SERVICES AND SOFTWARE ARE AT YOUR OWN SOLE RISK AND THAT THE SITE, SERVICES AND ANY SOFTWARE ARE PROVIDED ON AN “AS IS” AND “WITH ALLL FAULTS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, HOME FOR LIFE DESIGN DOES NOT WARRANT THAT THE OPERATION OF THE SITE, SERVICES AND/OR SOFTWARE WILL MEET YOUR REQUIREMENTS OR WILL BE UNINTERRUPTED OR ERROR-FREE.</p>
                <p><strong>Limitation of Liability</strong></p>
                <p>IN NO EVENT SHALL HOME FOR LIFE DESIGN BE LIABLE WITH RESPECT TO THE SITE, SERVICES AND/ OR SOFTWARE FOR (I) ANY AMOUNT IN THE AGGREGATE IN EXCESS OF THE FEES YOU HAVE ACTUALLY PAID TO HOME FOR LIFE DESIGN DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT(S) GIVING RISE TO SUCH LIABILITY; (II) ANY LOST PROFITS, LOST OR DAMAGED USER CONTENT OR OTHER DATA, OR FAILURE TO MEET ANY DUTY, INCLUDING WITHOUT LIMITATION GOOD FAITH AND REASONABLE CARE; OR (III) ANY INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER.</p>
                <p>YOU AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A REASONABLE ALLOCATION OF RISK AND IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN HOME FOR LIFE DESIGN AND YOU. YOU UNDERSTAND THAT THE SITE, SERVICES AND SOFTWARE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.</p>
                <p>CERTAIN STATES DO NOT ALLOW THE LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
                <p><strong>Arbitration</strong></p>
                <p>At Home for Life Design’s or your election, all disputes, claims or controversies arising out of or relating to this Agreement or the use of the Site, Software or Services that are not resolved by mutual agreement shall be resolved by binding arbitration to be conducted before JAMS, or its successor. Unless otherwise agreed by the parties, arbitration will be held in Salisbury, North Carolina before a single arbitrator mutually agreed upon by the parties, or if the parties cannot mutually agree, a single arbitrator appointed by JAMS, and will be conducted in accordance with the rules and regulations promulgated by JAMS. The arbitrator will not have the power to award damages in excess of the limitation on actual compensatory, direct damages set forth in this Agreement and may not multiply actual damages or award punitive damages or any other damages that are specifically excluded under this Agreement, and each party hereby irrevocably waives any claim to such damages. The arbitrator may, in his or her discretion, assess costs and expenses (including the reasonable legal fees and expenses of the prevailing part) against any party to a proceeding. Any party refusing to comply with an order of the arbitrators will be liable for costs and expenses, including attorneys’ fees, incurred by the other party in enforcing the award. Notwithstanding the foregoing, in the case of temporary or preliminary injunctive relief, any party may proceed in court without prior arbitration for the purpose of avoiding immediate and irreparable harm. The provisions of this arbitration section will be enforceable in any court of competent jurisdiction.</p>
                <p>Notwithstanding the provisions of the introductory section above, if Home for Life Design changes this “Arbitration” section after the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement), you may reject any such change by sending Home for Life Design written notice within thirty (30) days of the date such change became effective. By rejecting any change, you are agreeing that you will arbitrate any dispute between you and Home for Life Design in accordance with the provisions of this section as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement).</p>
                <p><strong>General Provisions</strong></p>
                <p>This Agreement shall be governed by, and construed in accordance with, the laws of the State of North Carolina, without regard to any choice of law, conflicts of law or other principles that would result in the applicable of the laws or regulations of any other jurisdiction. Subject to the section titled “Arbitration”, any legal action or proceeding relating to this Agreement shall be instituted in a state or federal court in Rowan County, North Carolina. You and Home for Life Design agree to submit to the jurisdiction of, and agree that venue is proper in, these courts in any such legal action or proceeding.</p>
                <p>This Agreement and the rights and obligations herein are personal to you, and you may not assign or otherwise transfer this Agreement or any of your rights or obligations hereunder, by operation of law or otherwise, without the prior written consent of Home for Life Design. Home for Life Design may freely assign this Agreement.</p>
                <p>If any one or more of the provisions of this Agreement are for any reason held to be invalid, illegal or unenforceable by a court of competent jurisdiction, the remaining provisions of this Agreement shall be unimpaired and shall remain in full force and effect, and the invalid, illegal or unenforceable provision(s) shall be replaced by a valid, legal and enforceable provision or provisions that comes closest to the intent of the parties underlying the invalid, illegal or unenforceable provision(s).</p>
                <p>The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.</p>
                <p>If Home for Life Design is unable to perform any obligation under this Agreement because of any matter beyond its reasonable control, such as lightning, flood, exceptionally severe weather, fire, explosion, war, civil disorder, industrial disputes (whether or not involving employees of Home for Life Design), acts of local or central government or other competent authorities, problems with telecommunications providers, hostile network attacks or other events beyond Home for Life Design’s reasonable control (each, a “Force Majeure Event”), Home for Life Design will have no liability to you for such failure to perform; provided, however, that Home for Life Design shall resume performance promptly upon removal of the circumstances constituting the Force Majeure Event. If any Force Majeure Event continues for more than sixty (60) days, either Home for Life Design or you may terminate this Agreement by delivery of written notice to the other party. You will remain responsible for all Fees incurred through the last day the Services were available.</p>
                <p>If you and Home for Life Design have executed a separate Subscription Agreement applicable to your access to and use of the Site, Services and/or Software, then the terms and conditions of such Subscription Agreement shall prevail to the extent of any conflict with the terms and conditions of this Agreement. In all other cases, this Agreement constitutes the entire agreement between Home for Life Design and you with respect to its subject matter, and supersedes all prior communications and proposals, whether electronic, oral or written, between Home for Life Design and you. No waiver or modification of any of the provisions of this Agreement shall be binding unless in writing and signed by a duly authorized representative of each party.</p>
          
          </div>
         
          </div>
          
      </div>
  </div>
</template>

<script>
import SecondNavBar from '../../components/LandingPage/SecondNavBar'
export default {
    components:{
        SecondNavBar
    }
}
</script>

<style>

</style>